import * as React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Navigation from "../components/navigation/navigation"

import Header from "../components/sections/Header"
import Features from "../components/sections/Features"
import Footer from "../components/sections/footer/Footer"
import GetStarted from "../components/sections/GetStarted"

const PlatformPage = () => (
    <Layout>
        <SEO title="Ergo Platform" />
        <Navigation />
        <Header />
        <Features />
        <GetStarted />
        <Footer />
    </Layout>
)

export default PlatformPage
